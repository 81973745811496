import { useCallback, useMemo } from "react";

import { auth } from "../../utils/firebase/configs";
import { useLogin } from "./useLogin";

export const useRegister = () => {
  const { login } = useLogin();

  const onRegister = useCallback(
    async (email: string, password: string, username: string) => {
      try {
        await auth.createUserWithEmailAndPassword(email, password);
        await auth.currentUser?.updateProfile({
          displayName: username,
        });
        await login();
      } catch (error) {
        console.log(error);
      }
    },
    [login]
  );

  return useMemo(
    () => ({
      onRegister,
    }),
    [onRegister]
  );
};
