import React from "react";

import { Link } from "gatsby";

import PrimaryButton from "./Primary";

function HomeButton() {
  return (
    <Link to="/">
      <PrimaryButton className="bg-primary hover:bg-quaternary text-white p-2">
        Back to home
      </PrimaryButton>
    </Link>
  );
}

export default HomeButton;
