import React, { FormEvent, useState } from "react";


import { navigate } from "gatsby";

import HomeButton from "../../components/common/Buttons/Home";
import PrimaryButton from "../../components/common/Buttons/Primary";
import BaseInput from "../../components/common/Inputs/BaseInput";
import PasswordInput from "../../components/common/Inputs/Password";
import { useRegister } from "../../hooks/auth/useRegister";

const initialState = {
  username: "",
  email: "",
  password: "",
  confirmPassword: "",
  error: "",
};

const RegisterPage: React.FunctionComponent = () => {
  const [form, setForm] = useState(initialState);

  const { onRegister } = useRegister();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    onRegister(form.email, form.password, form.username);
    navigate("/");
  };

  return (
    <div className="container h-screen flex justify-center">
      <div className="p-8 bg-white rounded-lg bg-gray-100 pb-10">
        <header className="border bg-blue-400 lg:text-3xl text-4xl text-white dark:white font-bold">
          Register
        </header>
        <br />
        <form onSubmit={handleSubmit}>
          <BaseInput
            id={"username"}
            name={"username"}
            title="username"
            type={"username"}
            value={form.username}
            placeholder={"username"}
            onChange={onChange}
          />

          <BaseInput
            id={"email"}
            name={"email"}
            title="email"
            type={"email"}
            value={form.email}
            placeholder={"email"}
            onChange={onChange}
          />

          <PasswordInput
            id={"password"}
            name={"password"}
            title="Password"
            value={form.password}
            placeholder={"password"}
            onChange={onChange}
          />

          <PasswordInput
            id={"confirmPassword"}
            name={"confirmPassword"}
            title="Confirm Password"
            value={form.confirmPassword}
            placeholder={"confirm password"}
            onChange={onChange}
          />

          <PrimaryButton disabled={false} type={"submit"}>
            Register
          </PrimaryButton>

          <HomeButton />
        </form>
      </div>
    </div>
  );
};

export default RegisterPage;
