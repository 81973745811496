import React, { createRef, useEffect, useState } from "react";

import { CrossIcon } from "../../Icons/CrossIcon";
import { ErrorIcon } from "../../Icons/ErrorIcon";
import { ShowIcon } from "../../Icons/ShowIcon";
import { TickIcon } from "../../Icons/TickIcon";
import "./input.css";

type IReactInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

interface IBaseInputProps extends IReactInputProps {
  errorText?: string;
}

const BaseInput: React.FunctionComponent<IBaseInputProps> = ({
  maxLength,
  required = true,
  title,
  type,
  value,
  onBlur: onNewBlur,
  ...props
}) => {
  const inputRef: React.RefObject<HTMLInputElement> = createRef();
  const [visited, setVisited] = useState(false);
  const [errorText, setErrorText] = useState("");

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (inputRef.current?.value.length === 0) {
      setErrorText("Error: Cannot be empty");
      e.target.classList.add("error");
    } else {
      setErrorText("");
      e.target.classList.add("error");
    }

    e.target.classList.add("visited");
    setVisited(true);
    onNewBlur && onNewBlur(e);
  };

  return (
    <p className="w-full">
      <div className="flex flex-col relative">
        <input ref={inputRef} required type={type} onBlur={onBlur} {...props} />
        <label className="title">
          <hr />
          <span>{title}</span>
        </label>
        {required && type !== "date" && (
          <div className="icons">
            {type === "password" && (
              <label className="icon eye h-5 w-5 ">
                <ShowIcon />
              </label>
            )}

            {visited && errorText.length === 0 && (
              <label className="icon tick">
                <TickIcon className="fill-current text-green-700" />
              </label>
            )}

            {errorText.length > 0 && (
              <label className="icon error">
                <ErrorIcon className="fill-current text-red-700" />
              </label>
            )}
          </div>
        )}
      </div>

      {required && type !== "date" && (
        <div className="flex justify-between">
          <label className="icon cross">
            {errorText?.length > 0 && (
              <>
                <CrossIcon className="fill-current text-red-700" />
                <span className="msg error">Error: Cannot be empty</span>
              </>
            )}
          </label>

          {type === "text" && (
            <label className="count">
              <span>
                {value?.toString().length ?? 0}/{maxLength}
              </span>
            </label>
          )}
        </div>
      )}
    </p>
  );
};

export default BaseInput;
