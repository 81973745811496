import React, { useState } from "react";

import PrimaryButton from "../Buttons/Primary";
import BaseInput, { IBaseInputProps } from "./BaseInput";

const PasswordInput: React.FunctionComponent<IBaseInputProps> = ({
  ...props
}) => {
  const [isShow, setIsShow] = useState(true);

  const onClick = (e: React.MouseEvent): void => {
    e.preventDefault();
    setIsShow(!isShow);
  };

  return (
    <>
      <BaseInput
        title="password"
        type={isShow ? "password" : "text"}
        {...props}
      />
      
      {isShow ? (
        <PrimaryButton disabled={false} onClick={onClick}>
          show
        </PrimaryButton>
      ) : (
        <PrimaryButton disabled={false} onClick={onClick}>
          hide
        </PrimaryButton>
      )}
    </>
  );
};

export default PasswordInput;
